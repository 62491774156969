<script setup lang="ts">
const props = defineProps<{
  checked: boolean
  name: string
  disableAdd?: boolean
}>()

defineEmits(['update:checked'])

const checkedState = useVModel(props, 'checked')

const onChange = () => {
  const newValue = !checkedState.value
  // Cannot add more
  if (props.disableAdd && newValue) return
  checkedState.value = !checkedState.value
}
</script>
<template>
  <div
    data-test="checkbox"
    class="mcd-checbox-box relative h-[1.5rem] w-[1.5rem] cursor-pointer"
    @click="onChange"
  >
    <input :checked="checkedState" type="checkbox" :name="name" class="mcd-checkbox" />
    <span class="checkmark"></span>
  </div>
</template>

<style scoped>
.mcd-checbox-box input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.mcd-checbox-box .checkmark {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50%), -50%);
  height: 25px;
  width: 25px;
  background-color: white;
  @apply rounded border border-mcd-darkGrey;
}

.mcd-checbox-box :hover input ~ .checkmark {
  background-color: #949494;
}

.mcd-checbox-box input:checked ~ .checkmark {
  @apply bg-mcd-main;
}

.mcd-checbox-box .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.mcd-checbox-box input:checked ~ .checkmark:after {
  display: block;
}

.mcd-checbox-box .checkmark:after {
  @apply border border-mcd-black;
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
